<!--footer section -->
<footer [class]="class">
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-title footer-mobile-title">
                        <h4>Acerca de:</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="footer-logo">
                            <img [src]="themeLogo" alt="logo" class="img-fluid">
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                        <button class="btn btn-dc mt-3">Mi cuenta</button>
                        <!--div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div-->
                    </div>
                </div>
                <div class="col offset-xl-1">
                    <div class="sub-title">
                        <div class="footer-title border-bottom">
                            <h4>Categorias:</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Tránsito</a></li>
                                <li><a href="javascript:void(0)">Administración pública</a></li>
                                <li><a href="javascript:void(0)">Obras públicas</a></li>
                                <li><a href="javascript:void(0)">Salud</a></li>
                                <li><a href="javascript:void(0)">Seguridad pública</a></li>
                                <li><a href="javascript:void(0)">Ambiental</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title border-bottom">
                            <h4>Siguenos en:</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li><i class="fa fa-facebook"></i>Facebook</li>
                                <li><i class="fa fa-twitter"></i>Twitter</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> Denuncia México {{ today | date:'y'}}</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <p class="text-right">Términos de uso</p>
                    <!--div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div-->
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->