import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const d = new Date(value); 
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
     
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));

    if (Number.isNaN(seconds)) {
        return '';
    }
    else if (seconds <= 45) {
        return 'Hace unos segundos';
    }
    else if (seconds <= 90) {
        return 'Hace un minuto';
    }
    else if (minutes <= 45) {
        return `Hace ${minutes} minutos`;
    }
    else if (minutes <= 90) {
        return 'Hace una hora';
    }
    else if (hours <= 22) {
        return hours + ' horas atras';
    }
    else if (hours <= 36) {
        return 'Hace un día';
    }
    else if (days <= 25) {
        return `Hace ${days} días`;
    }
    else if (days <= 45) {
        return 'Hace un mes';
    }
    else if (days <= 345) {
        return `Hace ${months} meses`;
    }
    else if (days <= 545) {
        return 'Hace un año';
    }
    else {
        // (days > 545)
        return `Hace ${years} años`;
    }
  }

  getSecondsUntilUpdate(seconds){
        const /** @type {?} */ min = 60;
        const /** @type {?} */ hr = min * 60;
        const /** @type {?} */ day = hr * 24;
        if (seconds < min) {
            // less than 1 min, update every 2 secs
            return 2;
        }
        else if (seconds < hr) {
            // less than an hour, update every 30 secs
            return 30;
        }
        else if (seconds < day) {
            // less then a day, update every 5 mins
            return 300;
        }
        else {
            // update every hour
            return 3600;
        }
    }
}
