<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 row">
                <div *ngIf="image" class="mr-15">
                    <img [src]="image" width="50" height="50">
                </div>
                <div class="page-title">
                    <h2>{{ title }}</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="'/'">Inicio</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb }}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb End -->