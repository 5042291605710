import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';  

@Component({
  selector: 'app-aviso-sesion',
  templateUrl: './aviso-sesion.component.html',
  styleUrls: ['./aviso-sesion.component.scss']
})
export class AvisoSesionComponent implements OnInit {

  url : string;
  constructor(public modal : NgbActiveModal, public router: Router) { }

  ngOnInit(): void {
  }

  goToInit(){
    this.modal.close();
    this.router.navigate(['/auth/inciar-session'], { state: { redirect: this.router.url } });
  }

  goToCreate(){
    this.modal.close();
    this.router.navigate(['auth/registro'], { state: { redirect: this.router.url } });
  }

}
