import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { UserInterface } from '../model/user.model';
import { FirebaseClass } from './firebase';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends FirebaseClass {
  
  basePath = 'users';

  image = 'https://firebasestorage.googleapis.com/v0/b/cocotazo-d0d21.appspot.com/o/placeholder3.jpg?alt=media&token=95eb3e9c-7e7d-4f58-8fa9-b524c0a398c8';
  constructor() {
    super();
  } 

  /**
   * 
   * @returns 
   */
  getDenunciantes() {
    return this.getAllByRef( this.db.collection('users'));
  }
  
  /**
   * 
   * @param user 
   * @returns 
   */
  async initUserAdmin(user: any): Promise<any> {  
    return new Promise<any>( (resolve, reject) => {
      if ( user != undefined && user != null ) {
        this.db.collection('users').doc(user.uid).onSnapshot( doc => {
          if ( doc.exists ) {
            resolve(doc.data());
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  /**
   * 
   * @param claveDenunciante 
   * @returns 
   */
  getUserInfo(claveDenunciante: string) {
    return new Observable<any>(observable => {
      return this.db.collection('users').doc(claveDenunciante).onSnapshot( snapshot => { 
        observable.next( snapshot.data() );
      });
    });
  }

  async getUser( claveUsuario : string ){
    const snap = await this.db.collection('users')
                          .doc(claveUsuario)
                          .get();

    return snap.exists ? snap.data(): null;
  }

  /**
   * 
   * @param user 
   * @param nombre 
   * @param image 
   */
  async editarUsuerio(user: any, nombre: string, image = null, quit) {
    let imageUrl = user.image;
    
    if(quit){
      imageUrl = this.image;
    }else{
      if(image) {
        imageUrl = await this.uploadImage(image, `${this.basePath}/${user.uid}`, user.uid);
      }
    }

    this.db.collection('users').doc(user.uid).update({
      usuario: nombre,
      image: imageUrl,
    });

    /*
    user.updateProfile({
      displayName: nombre,
      photoURL: imageUrl,
    });*/
  }

  /**
   * 
   * @param uid 
   * @returns 
   */
  async recuperarSiguiendo(uid: string) {  
    const snapSeguidores = await this.db.collection('users')
          .doc(uid)
          .collection('siguiendo')
          .get();
    
   
    const users = Promise.all(snapSeguidores.docs.map( async it => { 
      const data = it.data(); 
      const user = await this.db.collection('users').doc(data.uid).get();
      if(user.exists) {
        const userResult = user.data();
        userResult.timestampSiguiendo = data.timestamp.seconds;
        return userResult;
      } else {
        return null;
      }
    })); 
    return users;
  }

  /**
   * 
   * @param uid 
   * @returns 
   */
  async recuperarSeguidores(uid: string) {  
    const snapSeguidores = await this.db.collection('users')
          .doc(uid)
          .collection('seguidores')
          .get();
    
    const users = Promise.all( snapSeguidores.docs.map( async it => { 
      const data = it.data(); 
      const user = await this.db.collection('users').doc(data.uid).get();
      if(user.exists) {
        const userResult = user.data();
        userResult.timestampSiguiendo = data.timestamp.seconds;
        return userResult;
      } else {
        return null;
      }
    })); 
    return users;
  }

  getFollowsObs( uid: string, ref : string ): Observable<any> {
    const dbRef = this.db.collection('users')
    .doc(uid)
    .collection(ref);

    return new Observable<any>(observable => {

      this.observable = observable;

      dbRef.onSnapshot( snapshot => {
        const arrayResult = [];
        snapshot.forEach( async (doc) => {
          const data = doc.data();
          const user = await this.db.collection('users').doc(data.uid).get();
          arrayResult.push(user.data());
        });

        observable.next( arrayResult );
      });

    });
  }

  /**
   * 
   * @returns 
   */
  async recuperarSeguidoresPopulares():  Promise<Array<any>> { 
    const snapSeguidores = await this.db.collection('denunciantes-populares') 
          .get();
    
    const users = Promise.all(snapSeguidores.docs.map( async it => { 
      const data = it.data(); 
      const user = await this.db.collection('users').doc(data.uid).get();
      const userResult = user.data();
    
      if(userResult) {
        userResult['timestampSiguiendo'] = data.timestamp != undefined ? data.timestamp : null;
  
        return userResult;
      } else {
        return null;
      }
    })); 

    return users;
  }

  /**
   * 
   */
  async sendMessage() {
    const sendNotifition = this.firebaseFunctions.httpsCallable('sendNotifition'); 
    await sendNotifition({
      titulo: 'Nuevo servicio',
      contenido: 'Prueba', 
      tokens: JSON.stringify(['dzlat3KexjZP8uS8LoPQvc:APA91bGYNhNHAwtQEMlN1VGTJObZo4TgK17KU-xceK2fRLEaXfXZTUShcH1BzbXWLj0sp7uwAfVBJJiRYy5E-U7XLcAX-uL5xL2pMBAht9N5aGMUWLuhopNV5wk9hLCSntqpCgzLSE-R']),
    });
  }

  /**
   * 
   * @param complaint 
   */
  async addToFavorites( complaint : any, user: any){
    const date = new Date();
    complaint['timestampFavorite'] = date;
    await this.db.collection('denuncias-publicadas').doc(complaint.id).collection('favoritos').doc(user.uid).set({'uid': user.uid});
    return await this.db.collection('users').doc(user.uid).collection('favoritos').doc(complaint.id).set( complaint );
  }

  async addToPostFavorites( post : any, user: any){
    const date = new Date();
    post['timestampFavorite'] = date;
    await this.db.collection('publicaciones').doc(post.id).collection('publicaciones-favoritas').doc(user.uid).set({'uid': user.uid});
    return await this.db.collection('users').doc(user.uid).collection('publicaciones-favoritas').doc(post.id).set( post );
  }

  async getFavs( idComp : string, user: any ){
    return await this.db.collection('users').doc(user.uid).collection('favoritos').doc(idComp).get();
  }

  async getPostFavs( idPost : string, user: any ){
    return await this.db.collection('users').doc(user.uid).collection('publicaciones-favoritas').doc(idPost).get();
  }

  async deleteFavs( idComp : string, user: any ){
    await this.db.collection('denuncias-publicadas').doc(idComp).collection('favoritos').doc(user.uid).delete();
    return await this.db.collection('users').doc(user.uid).collection('favoritos').doc(idComp).delete();
  }

  async deletePostFavs( idPost : string, user: any ){
    await this.db.collection('publicaciones').doc(idPost).collection('publicaciones-favoritas').doc(user.uid).delete();
    return await this.db.collection('users').doc(user.uid).collection('publicaciones-favoritas').doc(idPost).delete();
  }

  async getAllFavs( user : string): Promise<Array<any>>{
    const snapshot = await this.db.collection('users').doc(user).collection('favoritos').get();
    const complaints = this.db.collection('denuncias-publicadas');

    const dataResult = [];
    snapshot.forEach(async childSnapshot => {
      const data = childSnapshot.data();
      const source = await complaints.doc(data.id).get();
      if(source.data() != undefined)
        dataResult.push(data);
    });

    return dataResult;
  }

  async getPostAllFavs( user : string): Promise<Array<any>>{
    const snapshot = await this.db.collection('users').doc(user).collection('publicaciones-favoritas').get();
    const posts = this.db.collection('publicaciones');

    const dataResult = [];
    snapshot.forEach(async childSnapshot => {
      const data = childSnapshot.data();
      const source = await posts.doc(data.id).get();
      if(source.data() != undefined)
        dataResult.push(data);
    });

    return dataResult;
  }

  async setData( uid: string, item : any){
    this.db.collection('users').doc(uid).update({
      notificaciones : item
    });
  }

  getNotifies( uid : string) :  Observable<any> {
    const dbRef = this.db.collection('users')
    .doc(uid)
    .collection('notificaciones');

    return new Observable<any>(observable => {
      
      dbRef.orderBy('timestamp', 'desc').onSnapshot( snapshot => {
        const arrayResult = [];
        snapshot.forEach( (doc) => {
          const data = doc.data();
          arrayResult.push(data);
        });

        observable.next( arrayResult);
      });

    });
  }
}
