import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];
  user;

  constructor(private router: Router, public navServices: NavService, 
    private adminService: AdminService,) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    // excluir siguiendo del menu si no tiene sesion iniciada
    
    this.adminService.firebaseAuth.onAuthStateChanged( async (user) => { 
      this.user = user;    
      if(user != null && !user.isAnonymous) { 
        const temp = this.menuItems.find(it => it.title === 'Feed Seguidores');
        if(temp == undefined ) {
          this.menuItems.push(
            {
              title: 'Feed Seguidores', type: 'link', active: false,
              path: '/public/feed'
            },
          );
        }
        /////////////////////////////////
        const temp2 = this.menuItems.find(it => it.title === 'Favoritos');
        if(temp2 == undefined ) {
          this.menuItems.push(
            {
              title: 'Favoritos', type: 'link', active: false,
              path: '/public/favoritos'
            },
          );
        }
      } else {
        const index = this.menuItems.findIndex(it => it.title === 'Siguiendo');
        if(index != -1) {
          this.menuItems.splice(index, 1);
        }
        ////////////////////////
        const index2 = this.menuItems.findIndex(it => it.title === 'Favoritos');
        if(index2 != -1) {
          this.menuItems.splice(index2, 1);
        }
      }
    });
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
