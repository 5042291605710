<app-header-one [sticky]="true"></app-header-one>
<!-- ********************************************************************* -->
<div class="aviso container text-justify">
    <h2>Aviso de Privacidad</h2>
    <p>El siguiente aviso de privacidad se emite conforme a los lineamientos establecidos en la Ley Federal de Proteccion De Datos Personales En Pocesion De Los Particulares vigente y que tiene como limite territorial los Estados Unidos Mexicanos.</p>
    <h4>1.- Controlador de datos </h4>
    <p>Aviso de privacidad denuncia<br>
        José Jesús Ramírez Córdova<br>
        Colonia Lázaro Cárdenas del Río Manzana 27<br>
        Paraíso, Tabasco<br>
        jesusramirez@grupovolada.com.mx<br>
        +529331155277
    </p>
    <h4>2.- informacion que se recopila:</h4>
    <p>
        Datos personales que recabamos de forma directa cuando usted mismo nos los proporciona por diversos medios, como cuando se registra para ser parte de nuestras plataformas en aplicaciones o paginas web y  participa en nuestras promociones o nos da información con objeto de que le prestemos un servicio. 
        Los datos que obtenemos por este medio pueden ser, entre otros:
    </p>
    <ul>
        <li>Informacion personal al crear una cuenta ( nombre, imagen, correo y telefono).</li>
        <li>Informacion personal como su ubicación y telefono este ultimo como medio de autenticacion.</li>
    </ul>
    <h4>3.-  Uso que se le da a los datos recabados</h4>
    <p>
        Denuncia ciudadana recopila y le da los siguientes usos a sus datos como: aseguraros de proveer los servicios y productos requeridos por usted, con el objetivo de que la experiencia del usuario sea cómoda y ágil.<br>
        Se definen los siguientes como propositos principales:
    </p>
    <ul>
        <li>Brindar un servicio eficiente, comodo y unico para el usuario.</li>
        <li>Permitir que los usuarios puedan recibir notificaciones de las interacciones que se realizan al momento de navegar dentro de nuestras plataformas.</li>
    </ul>
    <p>
        Asi tambien Denuncia mexico cuenta con otros propositos no relacionados con el servicio brindado a travez de nuestras plataformas como son los siguientes.<br>
        propositos secundarios:
    </p>
    <ul>
        <li>Para desarrollo constante de nuestros servicios.</li>
        <li>Recopilacion de opiniones y retroalimentación.</li>
    </ul>
    <p>
        Denuncia mexico ofrece las siguientes opciones para que el usuario solicite que su informacion no se use para los propositos secundarios:<br>
        El usuario puede:
    </p>
    <ul>
        <li>Enviar un correo a la siguiente direccion (jesusramirez@grupovolada.com.mx) con la solicitud correctamente requisitada.</li>
    </ul>
    <h4>4.-  uso de cookies </h4>
    <p>
        Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias para la visualización de las páginas en ese servidor, nombre y contraseña.<br>
        Denuncia mexico usa las cookies y otras tecnologias de identificacion en sus aplicaciones, sitios web, correos electronicos y anuncios en linea con los siguientes propositos: 
    </p>
    <ul>
        <li>Permitir que el usuario tenga una experiencia unica.</li>
        <li>Facilitar recursos visuales dentro de nuestra plataforma.</li>
    </ul>
    <h4>5.- intercambio y divulgacion de la informacion </h4>
    <p>
        Con el objetivo de brindar una experiencia unica al usuario Denuncia mexico requiere intercambiar informacion con proveedores los cuales pueden incluir procesos de nuestros servicios como 
    </p>
    <ul>
        <li>Servicios de google (como: mencionar ejemplos) que permiten mejorar la experiencia del usuario y que son necesarios para la correcta ejecucion del servicio contratado.</li>
    </ul>
    <h4>6.-  DERECHOS ARCO </h4>
    <p>
        ¿Cómo puede limitar el uso o divulgación de sus datos personales?<br>
        Usted puede limitar el uso o divulgacion de sus datos personales mediante el proceso que se describe en el siguiente punto.<br>
        ¿Cómo acceder o rectificar sus datos personales o cancelar u oponerse a su uso?<br>
        Usted tiene derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando considere que no se requieren para alguna de las finalidades señalados en el presente aviso de privacidad, estén siendo utilizados para finalidades no consentidas, o bien, oponerse al tratamiento de los mismos para fines específicos.
        Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de la presentación de la solicitud respectiva en:<br>
        (describir procedimiento o incluir vínculo electrónico)<br>
        Su solicitud ARCO deberá contener la siguiente información:
    </p>
    <ul>
        <li>Nombre completo del titular y, en su caso, de su representante.</li>
        <li>Domicilio o cualquier medio para recibir notificaciones.</li>
        <li>Anexar copia de algún documento con el que acredite la identidad del titular de los datos personales.</li>
        <li>La solicitud debe acompañarse de copia simple de una identificación oficial vigente del titular de los datos personales.</li>
        <li>De ser posible, señalar el nombre y ubicación del archivo o registro dedatos, así como el área dónde se encuentren.</li>
        <li>La descripción clara y precisa de los datos personales materia de la solicitud de derechos ARCO.</li>
        <li>Descripción del derecho que se quiere ejercer o de lo que solicita.</li>
        <li>En su caso, documentos o información que faciliten la localización delos datos personales.</li>
    </ul>
    <p>
        Los plazos para atender su solicitud ARCO son los siguientes:<br>
        Dentro de los primeros 30 dias habiles en los cuales se hara revision de su solicitud con apego a las normas legales vigente para la deteccion del derecho arco que se quiere ejercer.<br>
        De proceder la solicitud ARCO se contaran con 15 dias habiles para realizar las acciones correspondientes y asi atender al usuario solicitante.<br>
        De no proceder su solicitud ARCO se notificara al usuario en los primeros 7 dias habiles despues de vencerse el plazo de revision de la solicitud y se indicara las razones o motivos por los cuales no procede la solicitud. 
    </p>
    <h4>7.- actualizaciones y modificaciones al aviso de privacidad </h4>
</div>
<!-- ********************************************************************* -->
<app-footer-one></app-footer-one>
