import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  
  constructor(private angularFireMessaging: AngularFireMessaging, private afMessaging: AngularFireMessaging) {
    this.angularFireMessaging.usePublicVapidKey('BEShcUMMmvo-dKZwuzyBXV7yBgGlJ4yDWopIiw9k8BYPobQjwKd76eoLa9B8caIZ7eVvhWsibg1HP7uqxzCbjPc');
    this.angularFireMessaging.requestPermission.subscribe( (r)=> {
      console.log(r)
    });
    
    this.angularFireMessaging.messages.subscribe((_messaging: AngularFireMessaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
 

    afMessaging.onMessage((payload) => {
      console.log(payload)
    })
  }

  requestPermission() {

    this.angularFireMessaging.requestToken.subscribe((token) => {
      console.log(token);
    },
    (err) => {
      console.error('Unable to get permission to notify.', err);
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe( (payload) => {
      console.log("new message received. ", payload);
      this.currentMessage.next(payload);
    });
  }
}