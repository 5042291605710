<!-- header start -->
<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
        <div class="container">

            <div class="row">
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li>Bienvenido a Denuncia México</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-right">
                    <ul class="header-dropdown">
                        <li class="onhover-dropdown mobile-account">
                            <i class="fa fa-user" aria-hidden="true"></i> My Account
                            <ul class="onhover-show-div">
                                <li>
                                    <a data-lng="en">
                  Login
                </a>
                                </li>
                                <li>
                                    <a data-lng="es">
                  Logout
                </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu border-section border-top-0">
                    <div class="menu-left">
                        <app-left-menu></app-left-menu>
                    </div>
                    <div class="brand-logo layout2-logo headerlogo">
                        <a [routerLink]="['/home/fashion']">
                            <img [src]="themeLogo" class="img-fluid" alt="logo">
                        </a>
                    </div>
                    <div class="menu-right pull-right">
                        <app-settings></app-settings>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row ">
            <div class="col-lg-12">
                <div class="main-nav-center">
                    <app-menu></app-menu>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header end -->