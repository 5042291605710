import { Component, OnInit, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent implements OnInit {

  @Input() animation : boolean;
  options: AnimationOptions = {
    path: '/assets/json/loading 1.json',
  };

  styles : Partial<CSSStyleDeclaration> = {
    position: 'fixed',
    zIndex: '200',
    top: '40vh',
    left: '40vw',
    opacity: '1'
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
