import { Pipe, PipeTransform } from '@angular/core';

enum Months {
  enero,
  febrero,
  marzo,
  abril,
  mayo,
  junio,
  julio,
  agosto,
  septiembre,
  octubre,
  noviembre,
  diciembre
}

@Pipe({
  name: 'dcdate'
})

export class DcdatePipe implements PipeTransform {

  transform(seconds: any){
    const date = new Date(seconds.seconds * 1000);
    const dayOfMonth = date.getDate();
    const nameOfMonth = Months[date.getMonth()];
    const year = date.getFullYear();

    const result =  dayOfMonth + ' ' + nameOfMonth + ' ' + year;

    return result;
  }

}
