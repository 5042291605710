import { Component, OnInit, Input, HostListener } from '@angular/core';  
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/logos/DM-logo2.svg'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  
  user;
  constructor(
    private adminService: AdminService,
    private router: Router
    ) { }

  ngOnInit(): void {
    
    
    this.adminService.firebaseAuth.onAuthStateChanged( async (user) => {
      this.adminService.initUserAdmin(user).then( us => {  
        this.user = us;
        if(this.user && this.user.bloqueado){
          Swal.fire("Usuario bloqueado", "Lo sentimos tu usuario está bloqueado, se cerrará tu sesión", "error");
          this.forceLogout().finally();
        }
      });
    });
  }

  // @HostListener Decorator
  /*
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }*/

  async forceLogout(){
    await this.adminService.firebaseAuth.signOut();
    this.router.navigate(['/public']);
    //await this.adminService.firebaseAuth.signInAnonymously();
  }
  
  async logout() {
    await this.adminService.firebaseAuth.signOut(); 
    
    Swal.fire({
      titleText: 'La sesión se ha cerrado correctamente',
      icon: 'success',
    });

    this.router.navigate(['/public']);

    // crear sesion anonima
    //await this.adminService.firebaseAuth.signInAnonymously();
  }

  goToLogin(){
    this.router.navigate(['/auth/inciar-session']);
  }
}
