import firebase from 'firebase';
import { UserInterface } from '../model/user.model';
import { Observable, Subscriber } from 'rxjs';
import { fireApp } from './tools';  
export class FirebaseClass {

  // usuario 
  user: UserInterface;
  imageUserDefault = 'assets/images/placeholder/placeholder3.jpg';
  voidImage = 'assets/images/product/placeholder.jpg';
  inteface: any;

  basePath = 'default';
  imageDefault = '';

  observable: Subscriber<any>;

  firebaseDB: firebase.database.Database;
  firebaseAuth: firebase.auth.Auth;
  messaging: any;
  firebaseFunctions: firebase.functions.Functions;
  db: firebase.firestore.Firestore;
  storage: firebase.storage.Storage;
  dbRef: firebase.firestore.CollectionReference; 

  interfaceModel: any;

  constructor() {
    this.firebaseDB = fireApp.database();
    this.db = fireApp.firestore();
    this.firebaseAuth = fireApp.auth();
    this.firebaseFunctions = fireApp.functions();
    this.storage = fireApp.storage();
    this.messaging = firebase.messaging();


  }
  /**
   * Generate an unique ID
   */
  getPushKey(): string {
    return this.basePath ? this.db.collection(this.basePath).doc().id : this.dbRef.doc().id;
  }

  /**
   * Get all records from a node
   */
  async getAll(): Promise<Array<any>> {

    const snapshot = await this.dbRef.get(); 
    const dataResult = [];
    snapshot.forEach(childSnapshot => {
      const data = childSnapshot.data();
      dataResult.push(data);
    });

    return dataResult;
  }
  
  
  getTimestampFirebase() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  getTimestampFirebaseByDate(date: Date) {
    return firebase.firestore.Timestamp.fromDate(date);
  }

  async getAllByRef(dbRef: any): Promise<Array<any>> {
    const snapshot = await dbRef.get(); 
    const dataResult = [];
    snapshot.forEach(childSnapshot => {
      const data = childSnapshot.data();
      dataResult.push(data);
    });

    return dataResult;
  }
  
  async get(id: string): Promise<any> {
    const snapshot = await this.dbRef.doc(id).get();
    return snapshot.data();
  }

  getObservableByRef(dbRef): Observable<any> {
    return new Observable<any>(observable => {

      dbRef.onSnapshot( doc => {
        observable.next( doc.data());
      });
    });
  }

  getObservable(id: string): Observable<any> {
    return new Observable<any>(observable => {
      this.observable = observable;
      this.dbRef.doc(id).onSnapshot( snapshot => {
        observable.next( snapshot.data() );
      });

    });
  }
  

  getAllObservableByRef( dbRef ): Observable<any> {
    return new Observable<any>(observable => {

      this.observable = observable;

      dbRef.onSnapshot( snapshot => {
        const arrayResult = [];
        snapshot.forEach( (doc) => {
          const data = doc.data();
          arrayResult.push(data);
        });

        observable.next( arrayResult );
      });

    });
  }

  cancelObservable(): void {
    this.observable.unsubscribe();
  }

  save(model: any): Promise<boolean> {
    return new Promise<boolean>( (resolve, reject) => {
      
      if (model.id === null || model.id === '' || model.id === undefined) {
        model.id = this.getPushKey();
      }
  
      if (model.image !== undefined) {
        if (model.image == null) {
          model.image = this.imageDefault;
        } else if ( !(typeof model.image === 'string') ) {
            this.saveImage( model );
            delete model.image;
        }
      }

      this.dbRef.doc(model.id).set( model ).then( () => {
        resolve(true);
      });

    });
  }

  saveImage( object, key: string = 'image' ): void {
    this.uploadImage( object.image, `${this.basePath}/${object.id}`, object.id).then( url => {
      // actualizar el modelo de la imagen
      object.image = url;
      // Se actualiza la imagen del usuario
      const uptObj = {};
      uptObj[key] = url;
      this.dbRef.doc(object.id).update(uptObj);
    });
  }

  uploadImage(file: any, path, name): Promise<string> {
    console.log('uploadImage');
    return new Promise( (resolve, reject ) => {

      const metadata = {
          contentType: file.files[0].name
      };

      this.storage.ref().child(path + `/${name}`).put(file.files[0]).then( (snapshot) => {
          resolve( snapshot.ref.getDownloadURL() );
      }).catch( (error) => {
          // console.log(error);
      });

    });
  }

  update(model: any): Promise<boolean> {
    console.log('update', model);
    
    return new Promise<boolean>( (resolve, reject) => {
      this.dbRef.doc(model.id).update(model).then(() => {
        resolve(true);
      }).catch(error => {
        resolve(false);
      });
    });
  }

  delete(id: string): Promise<boolean> {
    return this.dbRef.doc(id).delete().then((val) => {
      return true;
    }).catch((err) => {
      return false;
    });
  }



}
