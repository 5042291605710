import firebase from 'firebase';

const configFirebase = {
    apiKey: 'AIzaSyDdS-gcU8DYBDu6v6SSotGPYNNH8GLpzpw',
    authDomain: 'cocotazo-d0d21.firebaseapp.com',
    databaseURL: 'https://cocotazo-d0d21.firebaseio.com',
    projectId: 'cocotazo-d0d21',
    storageBucket: 'cocotazo-d0d21.appspot.com',
    messagingSenderId: '101381636564',
    appId: '1:101381636564:web:d5a64d6ba3a2c5118fd641'
};

export const fireApp = firebase.initializeApp(configFirebase);

