import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-nuevo-menu',
  templateUrl: './nuevo-menu.component.html',
  styleUrls: ['./nuevo-menu.component.scss']
})
export class NuevoMenuComponent implements OnInit {

  public menuItems: Menu[];
  user;

  constructor(private router: Router, public navServices: NavService, 
    private adminService: AdminService) { 
      this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
        this.router.events.subscribe((event) => {
        this.navServices.mainMenuToggle = false;
      });
  }

  ngOnInit(): void {
    // excluir siguiendo del menu si no tiene sesion iniciada
    this.adminService.firebaseAuth.onAuthStateChanged( async (user) => { 
      this.user = user;    
      if(user != null && !user.isAnonymous) { 
        const temp = this.menuItems.find(it => it.title === 'Siguiendo');
        if(temp == undefined ) {
          this.menuItems.push(
            {
              title: 'Siguiendo', type: 'link', active: false,
              path: '/public/siguiendo'
            },
          );
        }
      } else {
        const index = this.menuItems.findIndex(it => it.title === 'Siguiendo');
        if(index != -1) {
          this.menuItems.splice(index, 1);
        }
      }
    });
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
