import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// tslint:disable-next-line: member-ordering
	MENUITEMS: Menu[] = [
		{
			title: 'Denuncias', type: 'link', active: false,
			path: '/public/lista-denuncias'
		},
		{
			title: 'Comunidad', type: 'link', active: false,
			path: '/public/comunidad'
		},
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>([]);

	// Windows width
	@HostListener('window:resize', ['$event'])
	
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

}
