import { Component, PLATFORM_ID, Inject, OnInit, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from './shared/services/admin.service';
import Swal from 'sweetalert2';
import { MessagingService } from './shared/services/messaging.service';
import firebase from "firebase";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  message;
  offline : boolean = false;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,
    private adminService: AdminService, 
    ) {
      
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
  }
 async ngOnInit() {

    let ids = ["1KTRSkfinzOADHzvaRvnLTAQ3xa2", "TF9VmXF75FWGLWz2aAPKUj5Bcsz2"]
    var data = [];
    var arr = [];

    for await (let id of ids){
      let docs = await firebase.firestore()
          .collection("publicaciones")
          .where("autor.uid", "==", id).orderBy("timestamp", "desc").get();
      data.push(docs);
    }

    
    data.forEach(item => {
      item.forEach(doc => {
        arr.push(doc.data());
      });
    });
    console.log("feed", arr);

    this.createOnline().subscribe(isOnline => {
      if(!isOnline){
        Swal.fire("Sin conexión", "No dispones de conexión a internet", "error");
      }else{
        //console.log(this.adminService.firebaseAuth.currentUser);
        this.adminService.firebaseAuth.onAuthStateChanged( async (user) => {  
          //console.log('onAuthStateChanged');
          if(user == null) {
            // crear sesion anonima
            //this.adminService.firebaseAuth.signInAnonymously();
          }
        });
      }
    });
    // this.initNotification();
  }

  createOnline() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  async initNotification() {
   
    const token = await this.adminService.messaging.getToken({
      vapidKey: 'BEShcUMMmvo-dKZwuzyBXV7yBgGlJ4yDWopIiw9k8BYPobQjwKd76eoLa9B8caIZ7eVvhWsibg1HP7uqxzCbjPc',
    });
    console.log('token', token)

    this.adminService.messaging.onMessage( (e)=> {
      console.log(e);
    });


     
    this.adminService.sendMessage();
  }

}
