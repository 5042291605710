<header class="sticky" [ngClass]="class">
    <div class="mobile-fix-option"></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left">
                        <div class="brand-logo">
                            <a routerLink="/" id="headerlogo" class="row">
                                <img [src]="themeLogo" class="img-fluid" alt="logo">
                            </a>
                        </div>
                    </div>
                    <div class="menu-right pull-right">
                        <div>
                            <app-menu></app-menu>
                        </div>
                        <div class="icon-nav">
                            <ul>
                                <li class="vertLine"></li>
                                <li class="onhover-div search-widgets" id="search-widgets">
                                    <button class="btn btn-dc" *ngIf="user == null || user == undefined" (click)="goToLogin()">Iniciar Sesión</button>
                                    <div *ngIf="user">
                                        <ul class="header-dropdown">
                                            <li class="onhover-dropdown mobile-account icono3-dc">
                                                <i class="fa fa-user" aria-hidden="true"><span class="ml-2">{{ user?.usuario }}</span></i>
                                                <ul class="onhover-show-div">
                                                    <li *ngIf="user != null" class="item borbot">
                                                        <a data-lng="es" [routerLink]="['/mi-perfil']">
                                                            <span class="item-color">Mi perfil</span>
                                                        </a>
                                                    </li>
                                                    <li *ngIf="user != null" class="item borbot">
                                                        <a data-lng="es" [routerLink]="['/mi-perfil/notificaciones']">
                                                            <span class="item-color">Notificaciones</span>
                                                        </a>
                                                    </li>
                                                    <li *ngIf="user != null" class="item" style="cursor: pointer;">
                                                        <a data-lng="es" (click)="logout()">
                                                            <span class="item-color">
                                                                Cerrar sesión
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>