// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  instagram_token: 'INSTA_TOKEN',
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  firebase: {
    apiKey: 'AIzaSyDdS-gcU8DYBDu6v6SSotGPYNNH8GLpzpw',
    authDomain: 'cocotazo-d0d21.firebaseapp.com',
    databaseURL: 'https://cocotazo-d0d21.firebaseio.com',
    projectId: 'cocotazo-d0d21',
    storageBucket: 'cocotazo-d0d21.appspot.com',
    messagingSenderId: '101381636564',
    appId: '1:101381636564:web:d5a64d6ba3a2c5118fd641'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
